import { useContext, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Context from "../../context/context";
import classes from "./Navigation.module.css";
import parse from "html-react-parser";

const Navigation = (props) => {
  const { language } = useContext(Context);

  let attachedClasses = [classes.nav, classes.close];

  if (props.isMenuOpen) {
    attachedClasses = [classes.nav, classes.open];
  }

  useEffect(() => {}, [language]);
  return (
    <>
      <nav className={attachedClasses.join(" ")}>
        <ul>
          <li>
            <NavLink
              to="/ourProducts"
              className={({ isActive }) => (isActive ? " active" : "")}
              onClick={props.clicked}
            >
              {parse(
                language === "sv" ? "V&aring;ra produkter" : "Our products"
              )}
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/ourIcon"
              className={({ isActive }) => (isActive ? " active" : "")}
              onClick={props.clicked}
            >
              <>{parse(language === "sv" ? "V&aring;r ikon" : "Our icon")}</>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/ourStory"
              className={({ isActive }) => (isActive ? " active" : "")}
              onClick={props.clicked}
            >
              <>
                {parse(language === "sv" ? "V&aring;r historia" : "Our story")}
              </>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/whyBambu"
              className={({ isActive }) => (isActive ? " active" : "")}
              onClick={props.clicked}
            >
              {parse(language === "sv" ? "Varf&ouml;r bambu?" : "Why bamboo?")}
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/dentistRecommends"
              className={({ isActive }) => (isActive ? " active" : "")}
              onClick={props.clicked}
            >
              {parse(
                language === "sv"
                  ? "Tandl&auml;karen rekommenderar"
                  : "Dentist recommendatioins"
              )}
            </NavLink>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default Navigation;
