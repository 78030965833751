import { useContext, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import classes from './Instagram.module.css'
import InstagramImage from './InstagramImage/InstagramImage'
import Backdrop from '../../UI/Modal/Backdrop'
import Modal from '../../UI/Modal/Modal'
import Context from '../../context/context'
import Button from '../../UI/Button/Button'

const Instagram = (props) => {
  const { language } = useContext(Context)
  const [isLoading, setIsLoading] = useState(false)
  const [instagramMedia, setInstagramMedia] = useState([])
  const [intermediateInstagramMedia, setIntermediateInstagramMedia] = useState(
    [],
  )
  const [instagramMediaFetched, setInstagramMediaFetched] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalData, setModalData] = useState(null)
  const [currentMediaBlock, setCurrentMediaBlock] = useState(0)
  const requestFields = [
    'id',
    'media_url',
    'media_type',
    'permalink',
    'caption',
  ]
  const apiAccessToken = process.env.REACT_APP_INSTAGRAM_KEY

  const splitArray = (arr, rows) => {
    const itemsPerRow = Math.ceil(arr.length / rows)
    return arr.reduce((acc, val, ind) => {
      const currentRow = Math.floor(ind / itemsPerRow)
      if (!acc[currentRow]) {
        acc[currentRow] = [val]
      } else {
        acc[currentRow].push(val)
      }
      return acc
    }, [])
  }

  const structureMedia = async (data) => {
    return new Promise((resolve) => {
      console.log('structureMedia Called', data.length)
      const structuredMedia = []
      data
        .filter((m) => m.media_type === 'IMAGE')
        .forEach((element) => {
          structuredMedia.push(element)
        })

      const totalNumberRows = structuredMedia.length / 9
      const restructuredArray = splitArray(structuredMedia, totalNumberRows)
      resolve(restructuredArray)
    })
  }
  const getInstagramMedia = async (nextDataUrl) => {
    try {
      const urlFirstCall = `https://graph.instagram.com/me/media?fields=${requestFields.join(
        ',',
      )}&access_token=${apiAccessToken}`

      const url = nextDataUrl ? nextDataUrl : urlFirstCall
      const instagramMedia = await fetch(url)
      if (!instagramMedia.ok) {
        console.log(`Response was not ok, status ${instagramMedia.status}`)
        setInstagramMediaFetched(true)
      } else {
        const data = await instagramMedia.json()
        console.log(`GetInstagramMedia, data is ${data}`)
        setIntermediateInstagramMedia((prev) => [...prev, ...data.data])
        if (data.paging.next) {
          getInstagramMedia(data.paging.next)
        } else {
          setInstagramMediaFetched(true)
        }
      }
    } catch (error) {
      console.log(`GetInstagramMedia, an error occured ${error}`)
    }
  }

  const instagramPictureClickedHandler = (mediaUrl, caption) => {
    setModalData({
      mediaUrl,
      caption,
    })
    setIsModalOpen(true)
  }

  const instagramPictureCloseHandler = () => {
    setIsModalOpen(false)
  }

  const backdrop = createPortal(
    <Backdrop
      modalOpen={isModalOpen}
      closeModal={instagramPictureCloseHandler}
    />,
    document.querySelector('body'),
  )
  const modal = createPortal(
    <Modal
      mediaUrl={modalData?.mediaUrl}
      caption={modalData?.caption}
      modalOpen={isModalOpen}
      closeModal={instagramPictureCloseHandler}
    />,
    document.querySelector('body'),
  )

  const showMoreHandler = () => {
    setCurrentMediaBlock((prev) => (instagramMedia[prev + 1] ? ++prev : prev))
  }

  useEffect(() => {
    try {
      if (intermediateInstagramMedia.length == 0) {
        setIsLoading(true)
        getInstagramMedia()
      }
    } catch (error) {
      console.log('Error occured', error)
    }
  }, [intermediateInstagramMedia])

  useEffect(() => {
    console.log('mediaBlock', currentMediaBlock, instagramMedia.length)
  }, [modalData, isModalOpen, instagramMedia, currentMediaBlock])

  useEffect(() => {
    const fetchMedia = async () => {
      console.log(
        'Instagram media fetched this far',
        intermediateInstagramMedia.length,
      )
      if (instagramMediaFetched) {
        const media = await structureMedia(intermediateInstagramMedia)
        setInstagramMedia(media)
        setIsLoading(false)
      }
    }

    fetchMedia()
  }, [isLoading, intermediateInstagramMedia, instagramMediaFetched])

  return (
    <div className={classes.Instagram}>
      {backdrop}
      {modal}
      <a
        className={classes.AccountName}
        href="https://www.instagram.com/easyfairy_official/"
        target="_blank"
      >
        <h1>{language === 'sv' ? 'Följ oss' : 'Follow'}</h1>
        @easyfairy_official
      </a>
      <div className={classes.ImageContainer}>
        {isLoading && (
          <p className={classes.LoadingMessage}>
            {language == 'sv'
              ? 'Hämtar instagrambilder...'
              : 'Getting images from instagram...'}
          </p>
        )}
        {!isLoading &&
          instagramMedia[currentMediaBlock] &&
          instagramMedia[currentMediaBlock].map((element, i) => {
            return (
              <InstagramImage
                clicked={() =>
                  instagramPictureClickedHandler(
                    element.media_url,
                    element.caption,
                  )
                }
                key={i}
                mediaUrl={element.media_url}
                caption={element.caption}
              />
            )
          })}
      </div>
      <div className={classes.ButtonContainer}>
        {/* <Button
          buttonType="ordinaryButton"
          onClick={showMoreHandler}
          label={language === 'sv' ? 'Visa fler' : 'Show more'}
          disabled={currentMediaBlock == instagramMedia.length - 1}
        /> */}
      </div>
    </div>
  )
}

export default Instagram
