import classes from './Supplier.module.css'

const Supplier = (props) => {
  const {
    Supplier,
    SpecialHeightHeltLogiskt,
    SpecialHeightKronan,
    PlaceInMiddleColumn,
    SpecialHeightSmaHjartan,
  } = classes;
  const { specialPlacement, specialTreatment, url, image, alt } = props
  const classesToApply = [Supplier]
  if (url.includes('kronan') && specialTreatment) {
    classesToApply.push(SpecialHeightKronan)
  } else {
    if (specialTreatment) {
      if (alt == "Små Hjärtan" && specialTreatment) {
        classesToApply.push(SpecialHeightSmaHjartan);
      } else{
        classesToApply.push(SpecialHeightHeltLogiskt)
      }
    } if (specialPlacement) {
      classesToApply.push(PlaceInMiddleColumn)
    }
  }
  return (
    <div className={classesToApply.join(' ')}>
      {url !== "" ? <a href={url} target="_blank" rel="noreferrer">
        <img src={image} alt={alt} />
      </a> : <img src={image} alt={alt} />}
    </div>
  )
}

export default Supplier
